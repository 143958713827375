function CallToAction1() {
    return (
        <section className="call-to-action call-to-action-1">
            <div className="call-to-action-1__wrapper container">
                <div className="call-to-action-1__wrapper__content text-colour-grey-three">
                    <div className="call-to-action-1__wrapper__content__title font-size-heading">Would you like to get in touch to get a demo of our system?</div>
                    <div className="call-to-action-1__wrapper__content__text font-size-text">Click on the Contact Us button to submit a query, and a member of the team will be in touch soon</div>
                </div>
                <a href="/" className="call-to-action-1__wrapper__content__button button">Contact Us</a>
            </div>

        </section>
    );
}

export default CallToAction1;