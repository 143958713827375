import data from './data.json';

function Footer1() {
    return (
        <footer className="footer footer-1">
            <div className="footer-1__wrapper container">
                <div className="footer-1__wrapper__upper">
                    <div className="footer-1__wrapper__upper__content text-colour-grey-one">
                        <div className="footer-1__wrapper__upper__content__title font-size-heading">{data.title}</div>
                        <div className="footer-1__wrapper__upper__content__text font-size-text">{data.text}</div>
                    </div>
                    <ul className="footer-1__wrapper__upper__links">
                        {data.links.map((link) => (
                            <li key={link.key} className="footer-1__wrapper__upper__links__item">
                                <a className="footer-1__wrapper__upper__links__item__link text-colour-grey-one font-size-text" href={link.link}>{link.text}</a>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="footer-1__wrapper__lower">
                    <div className="footer-1__wrapper__lower__text text-colour-grey-one font-size-text">{data.copyright}</div>
                    <ul className="footer-1__wrapper__lower__socials">
                        {data.socials.map((social) => (
                            <li key={social.key} className="footer-1__wrapper__lower__socials__item">
                                <a className={"footer-1__wrapper__lower__socials__item__link text-colour-grey-one font-size-heading la " + social.icon} href={social.link}>{social.text}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </footer>
    );
}

export default Footer1;