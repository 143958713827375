document.addEventListener("DOMContentLoaded", function(event) {
    //header1Toggle();
    header1Scroll();    
});

// function header1Toggle(){
//     let header1Toggle = document.querySelector('.header-1__toggle-menu');
//     let header1Navigation = document.querySelector('.header-1__navigation');
//     header1Toggle.addEventListener('click', () => {
//         header1Navigation.classList.toggle('header-1__navigation--open');
//         header1Toggle.classList.toggle('header-1__toggle-menu--open');
//         header1Toggle.classList.toggle('la-bars');
//         header1Toggle.classList.toggle('la-times');
//     });
// };

function header1Scroll(){
    window.addEventListener('scroll', () => {
        let header1 = document.querySelector('.header-1');
        header1.classList.toggle('header-1--sticky', window.scrollY > 0);
    });
}
