function Hero1(props) {
    return (
      <section className="hero hero-1">
        <div className="hero-1__media">
          {
            props.image 
            ? <img className="hero-1__media__image" src="/media/van.jpg" alt="Hero"/>
            : <video className="hero-1__media__video" autoPlay="true" muted="true" loop="true">
                  <source src="https://raw.githubusercontent.com/afrost1997/photos/main/Pexels%20Videos%202711127%20(1).mp4" type="video/mp4" />
              </video>        
          }
        </div>
        <div className="hero-1__content">
          <h1 className="hero-1__content__title container">FOGGY WINDOWS<span className="hero-1__content__title__highlight"></span></h1>
          <span className="hero-1__content__subtitle container text-size-heading">Have your sealed units gone? We can replace just the glass!</span>
          <div className="hero-1__content__buttons">
            <a href="tel:01270666619" className="hero-1__content__buttons__button button">Call Now</a>
          </div>
        </div>
      </section>
    );
  }

  export default Hero1;