import React from 'react';
import ReactDOM from 'react-dom';
import './styles/style.scss';
import './scripts/script.js';

//**Partials**
//blurb
import Blurb1 from './partials/blurb/blurb-1/blurb-1';
import Blurb2 from './partials/blurb/blurb-2/blurb-2';
//blurb-media
import BlurbMedia1 from './partials/blurb-media/blurb-media-1/blurb-media-1';
import BlurbMedia2 from './partials/blurb-media/blurb-media-2/blurb-media-2';
//call-to-action
import CallToAction1 from './partials/call-to-action/call-to-action-1/call-to-action-1';
//footer
import Footer1 from './partials/footer/footer-1/footer-1';
//full-width-media
import FullWidthMedia1 from './partials/full-width-media/full-width-media-1/full-width-media-1';
//header
import Header1 from './partials/header/header-1/header-1';
//hero
import Hero1 from './partials/hero/hero-1/hero-1';
//list-features
import ListFeatures1 from './partials/list-features/list-features-1/list-features-1';
//list-info
import ListInfo1 from './partials/list-info/list-info-1/list-info-1'
//stats
import Stats1 from './partials/stats/stats-1/stats-1';
import Stats2 from './partials/stats/stats-2/stats-2';
//teaser
import Teaser1 from './partials/teaser/teaser-1/teaser-1';
//title
import Title1 from './partials/title/title-1/title-1';

ReactDOM.render(
  <React.StrictMode>
    <Header1 />
    <main>
      <Hero1 image="true" />
      {/* <Blurb1 />
      <Blurb2 image="true" classes="text-left" />
      <Stats1 />
      <Stats2 image="true" />
      <CallToAction1 />
      <Teaser1 />
      <ListInfo1 />
      <BlurbMedia1 />
      <BlurbMedia2 />
      <Title1 />
      <FullWidthMedia1 />
      <ListFeatures1 /> */}
    </main>
    <Footer1 />   
  </React.StrictMode>,
  document.getElementById('body')
);
