

function Header1() {
    return ( 
        <header className="header header-1">
            <a href="/" className="header-1__logo">
                {/* <img className="header-1__logo__image" src="https://raw.githubusercontent.com/afrost1997/photos/main/F.jpg" alt="Logo" /> */}
                <span className="header-1__logo__text">FOGGY WINDOWS</span>
            </a>
            {/* <div className="header-1__navigation">
                <ul className="header-1__navigation__links">
                    <li className="header-1__navigation__links__item"><a href="/" className="header-1__navigation__links__item__link">About us</a></li>
                    <li className="header-1__navigation__links__item"><a href="/" className="header-1__navigation__links__item__link">About us</a></li>
                    <li className="header-1__navigation__links__item"><a href="/" className="header-1__navigation__links__item__link">About us</a></li>
                </ul>
            </div> */}
            {/* <span className="header-1__toggle-menu la la-bars"></span> */}
        </header>        
    );
}
export default Header1;